export const COLORS = [
	"rgb(43,206,72)",
	"rgb(240,163,255)",
	"rgb(0,117,220)",
	"rgb(153,63,0)",
	"rgb(76,0,92)",
	"rgb(225,225,225)",
	"rgb(0,92,49)",
	"rgb(255,204,153)",
	"rgb(128,128,128)",
	"rgb(148,255,181)",
	"rgb(143,124,0)",
	"rgb(157,204,0)",
	"rgb(194,0,136)",
	"rgb(0,51,128)",
	"rgb(255,164,5)",
	"rgb(255,168,187)",
	"rgb(66,102,0)",
	"rgb(255,0,16)",
	"rgb(94,241,242)",
	"rgb(0,153,143)",
	"rgb(224,255,102)",
	"rgb(116,10,255)",
	"rgb(153,0,0)",
	"rgb(255,255,128)",
	"rgb(255,255,0)",
	"rgb(255,80,)",
];
